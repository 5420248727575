import { Controller } from '@hotwired/stimulus';

import DataTable from 'datatables.net-dt';

export default class extends Controller {
  initialize() {
    let parsedConfig = JSON.parse(this.element.dataset['datatableConfig']);
    parsedConfig.ajax = this.element.dataset['source'];
    new DataTable(this.element, parsedConfig);
  }
}
